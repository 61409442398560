h1 {
  font-family: "Geist", sans-serif;
}

@keyframes gradient-wave {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient-wave {
  background-size: 300% 300%;
  animation: gradient-wave 16s infinite ease-in-out;
}

@keyframes fadePulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
.animate-fade-pulse {
  animation: fadePulse 4s infinite ease-in-out;
}
